import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BooksService, ShelfType } from '../../services/books.service';
import { AppService } from '../../services/app.service';
import { ToastService, ToastType } from '../../services/toast.service';
import { ProfileState } from '../../store/profile.state';
import { UserProvider } from '../../providers/user.provider';
import { TranslateService } from '@ngx-translate/core';
import { Book } from '../../models/book.model';
import { ButtonComponent } from '../button/button.component';
import { CompressedImagePipe } from '../../pipes/compressed-image.pipe';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-grid',
  templateUrl: './book-grid.component.html',
  styleUrls: ['./book-grid.component.scss'],
  imports: [ButtonComponent, CompressedImagePipe, AsyncPipe, RouterLink],
})
export class BookGridComponent {
  @Input({ required: true }) books: Array<Book> = [];
  @Input() shelfType: ShelfType | null = null;
  protected readonly ShelfType = ShelfType;
  protected readonly innerWidth = innerWidth;

  constructor(
    public appService: AppService,
    private profileState: ProfileState,
    private userProvider: UserProvider,
    private booksService: BooksService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
  ) {}

  async like(uuid: string, like = true): Promise<void> {
    this.userProvider.saveUserBook(uuid, like, this.profileState.currentProfile$.value?.id as string).subscribe({
      next: async () => {
        if (like) {
          void this.toastService.present({
            message: this.translateService.instant('PWA_toast_confirmation_savedToFavorites'),
            icon: 'assets/icon/heart-full.svg',
            type: ToastType.Positive,
            displayClose: true,
          });
        } else {
          const dismissData = await this.toastService.present({
            message: this.translateService.instant('PWA_toast_confirmation_removedFromFavorites'),
            type: ToastType.Negative,
            icon: 'assets/icon/crossed-heart.svg',
            displayClose: true,
            extraButton: {
              title: this.translateService.instant('PWA_web_myFavorites_toast_CTA_undoRemove'),
            },
          });

          if (dismissData?.cancelAction) {
            return this.like(uuid, true);
          }
        }

        // Update the favorite shelve data
        void this.booksService.updateFavoriteBooks();
      },
    });
  }
}
